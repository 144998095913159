<template>
  <section>
    <span class="text-center d-block f17">{{ exercise.exerciseName }}</span>
    <v-card class="d-flex flex-column align-items-center my-4 p-2" rounded="lg" flat color="grey lighten-4">
      <v-avatar class="mx-4 my-2 " size="96" rounded="lg">
        <v-img 
        :lazy-src="require('@/assets/images/profile-circle.png')"
        :src="
          exercise.profilePicture != null && exercise.profilePicture != ''
            ? exercise.profilePicture
            : require('@/assets/images/profile.jpg')
        "></v-img>
      </v-avatar>

        <span class="my-1 f14 font-weight-bold">
          {{ exercise.firstName + " " + exercise.lastName }}
        </span>

        <!-- <span class="my-1 f13 grey--text text--darken-1">
          {{ $t('Age:') }} {{ exercise.age }} {{ $t('Year') }}
        </span> -->

        <span class="my-1 f13 grey--text text--darken-1">
          {{ $t('Date of prescription:') }} {{ exercise.createDate | moment("dddd , jD jMMMM jYYYY") }}
        </span>

        <span class="my-1 f13 grey--text text--darken-1">
          {{ $t('Date of completion:') }} {{ exercise.updateDate | moment("dddd , jD jMMMM jYYYY") }}
        </span>
    </v-card>

    <v-card class="my-4 px-3 pt-2" rounded="lg" flat color="grey lighten-4">
        <div class="d-flex justify-content-between">
          <span class="f14 font-weight-bold black--text">{{ $t("Last mood") }}</span>

          <v-btn color="primary" depressed plain link min-width="auto" min-height="auto" height="auto"
            class="primary--text pa-1" :to="`/s/moods/${exercise.mobile}`">
            {{ $t('All') }}
          </v-btn>
        </div>
        <div class="d-flex align-items-center">
          <v-avatar class="my-2" size="96" rounded="circle">
            <v-carousel hide-delimiters cycle :show-arrows="false" :interval="2000" disabled>
              <v-carousel-item v-for="(t, i) in mood.moodTypes" :key="i" transition="scale-transition" reverse-transition="scale-transition">
                <img class="img-fluid pa-5" :src="t.resourceId"/>
              </v-carousel-item>
            </v-carousel>
          </v-avatar>
          <div>
            <span class="f14 font-weight-bold" v-for="(t, i) in mood.moodTypes">
              {{ $t(t.title) }}{{i != mood.moodTypes.length-1 ? "، " : ""}}
            </span>

            <span class="d-block my-1 f13 grey--text text--darken-1">
              {{ mood.updateDate | moment("from", "now") }}
            </span>
          </div>
        </div>
    </v-card>

    <v-card class="my-4 py-4 px-6 white--text d-flex justify-content-between align-items-center" rounded="lg" flat color="primary">
      {{ exercise.title }}
      <iconly name="arrow-down2" type="bold" />
    </v-card>

    <v-card class="my-2 " v-for="(exercise, index) in exerciseResult" :key="index" flat hover ripple>
      <b-list-group>
        <b-list-group-item class="d-flex align-items-center">
          <v-avatar color="green lighten-5" size="48" v-if="selectedType.coverPicture == 'TRUE_FALSE' && exercise.strength">
            <span class="green--text text--lighten-1 f18">+</span>
          </v-avatar>
          <v-avatar color="red lighten-5" size="48" v-else-if="selectedType.coverPicture == 'TRUE_FALSE' && !exercise.strength">
            <span class="red--text text--lighten-1 f18">-</span>
          </v-avatar>
          <v-avatar color="grey lighten-4" size="48" v-else>
            <span class="grey--text f18">{{ index + 1 }}</span>
          </v-avatar>
          <div class="d-flex flex-column ms-4 me-0 align-items-start justify-content-center">
            <p v-if="selectedType.coverPicture == 'TRUE_FALSE' && exercise.strength" class="f14 font-weight-bold m-0 green--text" v-html="exercise.content"></p>
            <p v-else-if="selectedType.coverPicture == 'TRUE_FALSE' && !exercise.strength" class="f14 font-weight-bold m-0 red--text" v-html="exercise.content"></p>
            <p v-else class="f14 font-weight-bold m-0 dark--text" v-html="exercise.content"></p>
          </div>
        </b-list-group-item>
      </b-list-group>
    </v-card>

  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();


export default {
  data() {
    return {
      exerciseId: null,
      exercise: {},
      exerciseResult: [],
      mood: {},
      moodTypes: [],
      selectedType: {},
    }
  },
  methods: {
    getExerciseType() {
      apiService.getExerciseType()
        .then((response) => {
          var exerciseTypeList = response.data;
          exerciseTypeList.forEach(element => {
            if (element.id == this.exercise.typeId) {
              this.selectedType = element;
              return;
            }
          });

        })
        .catch(() => {
          this.$router.go(-1);
        });
    },
    getExerciseAnswerByUsername() {
      apiService.getExerciseAnswerByUsername(this.exerciseId, this.exercise.mobile)
        .then((response) => {
          this.exerciseResult = response.data;
        })
        .catch((err) => {
          console.log(err)
          this.$router.go(-1);
        })
    },
    getLastMood() {
      apiService.getClientUserLatestMood(this.exercise.mobile)
        .then((response) => {
          this.mood = response.data;
          this.setMoodStatus();
        })
        .catch((err) => {
          console.log(err)
          this.$router.go(-1);
        })
    },
    setupMoodTypes() {
      var moodGood = {
        title: "Fine",
        index: 1,
        resourceId: require('@/assets/images/app/moods/ic_mood_good.svg'),
        backgroundColor: "#fef9f6",
        borderColor: "#db7e6c",
        shadowColor: "#50db7e6c",
      }

      var moodHopeless = {
        title: "Disappointed",
        index: 2,
        resourceId: require('@/assets/images/app/moods/ic_mood_hopeless.svg'),
        backgroundColor: "#fff9f3",
        borderColor: "#ff8000",
        shadowColor: "#50ff8000",
      }

      var moodWorried = {
        title: "Worried",
        index: 3,
        resourceId: require('@/assets/images/app/moods/ic_mood_worred.svg'),
        backgroundColor: "#fef9f6",
        borderColor: "#db7e6c",
        shadowColor: "#50db7e6c",
      }

      var moodStress = {
        title: "Stress",
        index: 4,
        resourceId: require('@/assets/images/app/moods/ic_mood_stress.svg'),
        backgroundColor: "#f7f7ff",
        borderColor: "#a29bfe",
        shadowColor: "#20a29bfe",
      }

      var moodSad = {
        title: "Sad",
        index: 5,
        resourceId: require('@/assets/images/app/moods/ic_mood_sad.svg'),
        backgroundColor: "#fff8f8",
        borderColor: "#e8252b",
        shadowColor: "#50e8252b",
      }

      var moodNervous = {
        title: "Nervous",
        index: 6,
        resourceId: require('@/assets/images/app/moods/ic_mood_upset.svg'),
        backgroundColor: "#fbfdff",
        borderColor: "#a0a5aa",
        shadowColor: "#50a0a5aa",
      }

      var moodSleepGood = {
        title: "Slept well",
        index: 7,
        resourceId: require('@/assets/images/app/moods/ic_mood_good_sleep.svg'),
        backgroundColor: "#f7f7ff",
        borderColor: "#a29bfe",
        shadowColor: "#20a29bfe",
      }

      var moodSleepBad = {
        title: "Slept badly",
        index: 8,
        resourceId: require('@/assets/images/app/moods/ic_mood_bad_sleep.svg'),
        backgroundColor: "#fff9f3",
        borderColor: "#ff8000",
        shadowColor: "#50ff8000",
      }

      var moodOther = {
        title: "Other",
        index: 9,
        resourceId: require('@/assets/images/app/moods/ic_mood_other.svg'),
        backgroundColor: "#ebffff",
        borderColor: "#00cec9",
        shadowColor: "#5000cec9",
      }

      this.moodTypes.push(moodGood);
      this.moodTypes.push(moodHopeless);
      this.moodTypes.push(moodWorried);
      this.moodTypes.push(moodStress);
      this.moodTypes.push(moodSad);
      this.moodTypes.push(moodNervous);
      this.moodTypes.push(moodSleepGood);
      this.moodTypes.push(moodSleepBad);
      this.moodTypes.push(moodOther);
    },
    setMoodStatus() {
      var moodTypeList = [];

      if (this.mood.reason0 == '0' &&
        this.mood.reason1 == '0' &&
        this.mood.reason2 == '0' &&
        this.mood.reason3 == '0' &&
        this.mood.reason4 == '0' &&
        this.mood.reason5 == '0' &&
        this.mood.reason6 == '0' &&
        this.mood.reason7 == '0'
      ) {
        moodTypeList.push(this.moodTypes[0]);
      }

      if (this.mood.reason0 == '1') {
        moodTypeList.push(this.moodTypes[1]);
      }
      if (this.mood.reason1 == '1') {
        moodTypeList.push(this.moodTypes[2]);
      }
      if (this.mood.reason2 == '1') {
        moodTypeList.push(this.moodTypes[3]);
      }
      if (this.mood.reason3 == '1') {
        moodTypeList.push(this.moodTypes[4]);
      }
      if (this.mood.reason4 == '1') {
        moodTypeList.push(this.moodTypes[5]);
      }
      if (this.mood.reason5 == '1') {
        moodTypeList.push(this.moodTypes[6]);
      }
      if (this.mood.reason6 == '1') {
        moodTypeList.push(this.moodTypes[7]);
      }
      if (this.mood.reason7 == '1') {
        moodTypeList.push(this.moodTypes[8]);
      }

      this.mood.moodTypes = moodTypeList;

    },

  },
  beforeMount() {
    this.setupMoodTypes();
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
  },
  mounted() {
    this.exerciseId = this.$route.params.exerciseId;

    if (this.$route.params.obj) {
      this.exercise = this.$route.params.obj;
    }
    else {
      this.$router.go(-1);
    }

    this.getExerciseAnswerByUsername();
    this.getLastMood();
    this.getExerciseType();
  }
}
</script>
